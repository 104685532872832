import React from 'react';
import Loader from './Loader';
// debugger;
function Loading(props) {
  if (props.error) {

    window.location.reload();
  } else if (props.pastDelay) {
    return <div className="loader-container"> <Loader /> </div>;
  } else {
    return null; 
  }
}

export default Loading;