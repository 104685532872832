import React from 'react';
import { render } from 'react-dom';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
// import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';
import { message } from 'antd';
const store = configureStore();
message.config({maxCount: 1})
// Sentry.init({
//   dsn: "https://c2f71ff1761d4b2a918da278671b59cc@o525114.ingest.sentry.io/5638649"
// });
render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
