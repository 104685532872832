import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
// import url from '../urlConstants';
// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"
// import socketIOClient from "socket.io-client";
// import CacheBuster from '../components/CacheBuster'

let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout/'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let AsyncAccount = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})
let SighnUp = loadable({
  loader: () => import('../../src/./routes/signUpComponent/signUp'),
  loading: LoadingComponent,
})
let PaymentMode = loadable({
  loader: () => import('../routes/user/routes/paidVersion'),
  loading: LoadingComponent,
})

let ExistingUserPayment = loadable({
  loader: () => import('../routes/user/routes/loginNewPage'),
  loading: LoadingComponent,
})
let PaymentSucess = loadable({
  loader: () => import('../routes/user/routes/sucessfulMessage'),
  loading: LoadingComponent,
});
let NewPayment = loadable({
  loader: () => import('../../src/routes/user/routes/paymentNew'),
  loading: LoadingComponent,
});
let PaymentConfirmation = loadable({
  loader: () => import('../routes/user/routes/Confirmation'),
  loading: LoadingComponent,
});
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      response: ""
    }
  }

  componentDidMount() {
    try {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });

      });
      if (this.props.location.pathname === '/user/newlogin') {
        document.title = 'B2B Registration'
      } else if (this.props.location.pathname === '/user/pay') {
        document.title = 'B2B Payment'
      } else if (this.props.location.pathname === '/user/staticPay') {
        document.title = 'B2B Payment (TEST)'
      }
      else {
        document.title = 'EasyScore For Students'
      }

      // caches.delete(true).then(res => {
      // })
    }
    catch (e) {
    }
  }

  render() {

    const { match, location } = this.props;
    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      return (<Redirect to={'/user/login'} />);
    } else if (location.pathname === '/user/dashboard') {
      return (<Redirect to={'/user/login'} />);
    }
    else if (location.pathname === '/user/signup') {
      return <SighnUp />
    }
    else if (location.pathname === '/user/payment') {
      return <PaymentMode />
    }
    else if (location.pathname === '/user/pay') {
      return <NewPayment />
    }
    else if (location.pathname === '/user/staticPay') {
      return <NewPayment />
    }
    else if (location.pathname === '/user/newlogin') {
      return <ExistingUserPayment />
    }
    // else if(location.pathname === `/user/b2bregistration`){
    //   return <B2bRegistration/>
    // }
    else if(location.pathname === `/user/thank-you` || location.pathname === `/user/confirmation`){
      return <PaymentConfirmation/>
    }
    else if (location.pathname === `/user/success/${parseInt(sessionStorage.getItem("studentId"))}`) {
      return <PaymentSucess />
    }

    else {
      return (
        <div id="app">
          <Switch>
            <Route path={`${match.url}app`} component={AsyncAppLayout} />
            <Route path={`${match.url}user`} component={AsyncAccount} />
            <Route path="*" component={AsyncException} />
          </Switch>
        </div>
      );
    }



  }
}

export default App;